export const environment = {
  production: false,
  apiUrl: "https://camkeeper-api-int.armadilloamarillo.cloud/api",
  emailRegex: new RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  ),
  phoneRegex: new RegExp(/^(?:6[0-9]|7[1-9]|9[1-9])[0-9]{7}$/),
  passwordRegex: new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d_~@#$%^&*+=`|{}:;¡!.¿?\"()\[\]-]{8,16}$/
  ),
  recaptcha: {
    siteKey: "6LeowSIpAAAAAIqjOnkwCROrErhWpINAX9J1I9gU",
  },
};
