// ApiCall Module
import { ApiCallModule, Configuration, ConfigurationParameters } from 'src/app/services/api-call';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';

/**
 * Configuration Api Call Factory
 */
export const ConfigurationApiCallFactory = (): Configuration => {
  const params: ConfigurationParameters = {
    basePath: environment.apiUrl,
    production: environment.production,
  };
  return new Configuration(params);
};

@NgModule({
  declarations: [],
  providers: [],
  imports: [
    ApiCallModule.forRoot(ConfigurationApiCallFactory),
  ],
})
export class ServicesModule { }
